<template>
  <div class="loginLog">
    <el-scrollbar class="elScroll">
      <template v-if="$route.name=='loginLog'">
        <div class="search_sList">
          <el-tabs v-model="activeName">
            <el-tab-pane label="登录日志" name="first"></el-tab-pane>
          </el-tabs>
          <div class="top_opera">
            <el-form :inline="true" :model="formInline" class="demo-form-inline" label-width="80px">
              <el-form-item label="操作人">
                <el-input v-model="formInline.operator" placeholder="操作人" clearable></el-input>
              </el-form-item>
              <el-form-item label="登录状态">
                <el-select v-model="formInline.resultEnum" placeholder="选择登录状态" clearable>
                  <el-option label="成功" value="SUCCESS"></el-option>
                  <el-option label="失败" value="FAIL"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="操作时间">
                <el-date-picker
                  v-model="timeVal"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
              </el-form-item>
            </el-form>
            <div class="btn_area">
              <el-button class="mainBtn" @click="onSubmit" plain>搜索</el-button>
              <el-button type="info" plain @click="resetEvent">重置</el-button>
            </div>
          </div>
        </div>
        <div class="table_sList">
          <el-table
            :data="tableData"
            stripe
            border
            style="width: 100%"
            align="center"
            max-height="700"
            row-key='id'
            @selection-change="handleSelectionChange">
            <!-- <el-table-column
              align="center"
              type="selection"
              :reserve-selection="true"
              width="55">
            </el-table-column> -->
            <el-table-column
              prop="createBy"
              label="操作人"
              align="center"
              width="220">
            </el-table-column>
            <el-table-column
              prop="remoteAddr"
              label="IP"
              align="center">
            </el-table-column>
            <el-table-column
              prop="status"
              label="登录状态"
              align="center"
              width="120"
              :formatter="regStatus">
            </el-table-column>
            <el-table-column
              prop="title"
              label="操作信息"
              align="center">
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="操作时间"
              align="center"
              width="180">
              <template slot-scope="scope">
                <span>{{timestampReturnDate(scope.row.createTime, 'datetime')}}</span>
              </template>
            </el-table-column>
          </el-table>
          <Pagination @changePage="changePage" :childMsg="childMsg"></Pagination>
        </div>
      </template>
      <template v-else>
        <publicChildren></publicChildren>
      </template>
    </el-scrollbar>
  </div>
</template>

<script>
import publicChildren from '../publicChildren'
import Pagination from '../Pagination'
import way from '../../api/encapsulation'
export default {
  name: 'loginLog',
  components: {
    publicChildren,
    Pagination,
  },
  data() {
    return {
      activeName: 'first',
      formInline: {
        endTime: '',
        startTime: '',
        page: 1,
        pageSize: 10,
        operator: '',
        resultEnum: ''
      },
      tableData: [],
      loading: false,
      childMsg: {
        current: 1,
        size: 10,
        total: 1
      },
      timeVal: '',
      idArr: [],
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        // console.log(newVal,oldVal)
        if(newVal.name == 'loginLog') {
          this.getLoginLogPageEvent();
        }
      }
    }
  },
  methods: {
    timestampReturnDate: way.timestampReturnDate,
    // 获取登录日志表格数据
    getLoginLogPageEvent() {
      this.loading = true;
      let obj = this.formInline;
      this.$request.getLoginLogPage(obj, res => {
        this.loading = false;
        if(res.code == 0){
          let {records, current, size, total} = res.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total
          }
        }
      })
    },
    handleSelectionChange(val) {
      if(val.length > 0){
        let idArr = val.map((item, index) => {
          return item.id
        })
        this.idArr = idArr;
      } else {
        this.idArr = [];
      }
    },
    regStatus(row) {
      let edu = String(row.status)
      let val = '';
      switch(edu) {
        case '0':
          val = '成功';
          break;
        case '9':
          val = '失败';
          break;
      }
      return val;
    },
    // 重置
    resetEvent() {
      this.formInline.operator = '';
      this.formInline.resultEnum = '';
      this.formInline.startTime = '';
      this.formInline.endTime = '';
      this.timeVal = '';
    },
    onSubmit() {
      if(this.timeVal) {
        this.formInline.startTime = way.dateReturnTimestamp(this.timeVal[0]);
        this.formInline.endTime = way.dateReturnTimestamp(this.timeVal[1]);
      }
      this.formInline.page = 1;
      this.formInline.pageSize = 10;
      this.getLoginLogPageEvent();
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.page = val.current;
      this.formInline.pageSize = val.size;
      this.getLoginLogPageEvent();
    },
  },
}
</script>

<style lang="less">
  .loginLog {
    height: 100%;
    .elScroll {
      height: 100%;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
    .search_sList {
      background-color: #fff;
      padding: 28px 40px;
      .top_opera {
        display: flex;
        .el-form {
          flex: 4;
        }
        .btn_area {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .table_sList {
      box-sizing: border-box;
      padding: 30px 40px;
      margin-top: 10px;
      background-color: #fff;
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #fff;
      }
      ::-webkit-scrollbar-thumb {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
          background-color: rgba(0, 0, 0, .1);
          border-radius: 3px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
      }
      .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
          border-right: none;
      }
      .el-pagination {
        text-align: right;
      }
    }
    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .pubToop {
    max-width: 600px;
  }
</style>