<template>
  <!-- <el-pagination class="page-box" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :current-page="childMsg.currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="childMsg.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="childMsg.total">
  </el-pagination> -->
  <el-pagination class="page-box" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :current-page="pageparm.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageparm.size" layout="total, sizes, prev, pager, next, jumper" :total="pageparm.total">
  </el-pagination>
</template>
<script>
export default {
  name: 'Pagination',
  props: ['childMsg'],
  watch: {
    childMsg: {
      immediate: true,
      handler(newVal, oldVal) {
        // console.log(newVal,oldVal)
        this.pageparm = newVal;
      }
    }
  },
  data() {
    return {
      pageparm: {
        current: 1,
        size: 10,
        total: 1
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      /**
       * 子传父
       * 参数1 父元素方法
       * 参数2 数据
       */
      this.pageparm.size = val
      this.$emit('changePage', this.pageparm)
    },
    handleCurrentChange(val) {
      /**
       * 子传父
       * 参数1 父元素方法
       * 参数2 数据
       */
      this.pageparm.current = val
      this.$emit('changePage', this.pageparm)
    }
  }
}
</script>

<style>
.page-box {
  margin: 10px auto;
}
</style>
