<template>
  <div class="publicChildren">
    <div class="pc_header">
      <span class="son_title">{{headTitle}}</span>
      <div class="right_pc_area">
        <span class="go_back" @click="goBack">返回上一级</span>
        <span class="bread_area">{{breadcrumb}}</span>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'publicChildren',
  data() {
    return {
      headTitle: '',
      breadcrumb: '',
      historyNum: -1
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        let newArr;
        if(newVal.query.routerType == 'nonPage') {
          // 非子路由跳转时，并且需要在该层级的子级展示的模块，进行处理
          newArr = this.breadcrumb.split('/')
          newArr.push(this.headTitle)
        } else {
          newArr = newVal.query.breadcrumb ? newVal.query.breadcrumb.split(',') : newVal.meta.breadcrumb ? newVal.meta.breadcrumb.split(',') : null;
        }
        let headTitle = newVal.query.headTitle || newVal.params.headTitle || newVal.meta.sname;
        this.headTitle = headTitle;
        this.breadcrumb = newArr ? newArr.join('/') : null;
      }
    },
  },
  methods: {
    goBack() {
      // console.log(this.$route);
      // return;
      let aidArr = ['specialExercises', 'refinedEdition']
      if(aidArr.includes(this.$route.name)) {
        let fullPath = sessionStorage.getItem('fatherRoute')
        this.$router.push(fullPath)
      } else {
        this.$router.go(this.historyNum);
      }
    }
  },
}
</script>

<style lang='less'>
  .publicChildren {
    height: 100%;
    .pc_header {
      height: 50px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #F4F7FC;
      box-sizing: border-box;
      padding: 0px 40px;
      background-color: #fff;
      span {
        display: inline-block;
        height: 100%;
        line-height: 50px;
        font-size: 12px;
        color: #1282FF;
        font-weight: 400;
      }
      .son_title {
        box-sizing: border-box;
        font-size: 14px;
        font-weight: 600;
        border-bottom: 3px solid #1282FF;
      }
      .go_back {
        cursor: pointer;
      }
      .bread_area {
        color: #777;
        margin-left: 42px;
      }
    }
  }
</style>